import { startCase } from "lodash";
import regex from "src/utils/regex";

export function generateRandomId(length = 10) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function transformPublicProfileName(inputString) {
  switch (inputString) {
    case "Writs_and_Summons":
      return "Court Writs";
    case "Writs_and_Summons_Less_Than_12_Months":
      return "Writs < 12 Months";
    case "Writs_and_Summons_More_Than_12_Months":
      return "Writs > 12 Months";
    case "Judgements":
      return "Court Actions";
    case "Judgements_Less_Than_12_Months":
      return "Actions < 12 Months";
    case "Judgements_More_Than_12_Months":
      return "Actions > 12 Months";
    case "Petitions":
      return "Petitions";
    case "Petitions_Less_Than_12_Months":
      return "Petitions < 12 Months ";
    case "Petitions_More_Than_12_Months":
      return "Petitions > 12 Months";

    default:
      return "";
  }
}

export function transformCreditHistoryName(name) {

  switch (name) {
    case "Credit_Enquiries":
      return "Credit Enquiries";
    case "Credit_Enquiries_Less_Than_12_Months":
      return "Credit Enquiries < 12 Months";
    case "Credit_Enquiries_More_Than_12_Months":
      return "Credit Enquiries > 12 Months";
    case "Broker_Dealer_Enquiries":
      return "Broker Dealer Enquiries";
    case "Broker_Dealer_Enquiries_Less_Than_12_Months":
      return "Broker Dealer Enquiries < 12 Months";
    case "Broker_Dealer_Enquiries_More_Than_12_Months":
      return "Broker Dealer Enquiries > 12 Months";
    case "Mercantile_Agent":
      return "Mercantile Agent Enquiries";
    case "Mercantile_Agent_Enquiries_Less_Than_12_Months":
      return "Mercantile Agent Enquiries < 12 Months ";
    case "Mercantile_Agent_Enquiries_More_Than_12_Months":
      return "Mercantile Agent Enquiries > 12 Months";
    case "Defaults":
      return "Payment Defaults > 12 Months";
    case "Defaults-12":
      return "Defaults < 12 Months";
    case "Defaults_More_Than_12_Months":
      return "Defaults > 12 Months  ";
    case "Telco_Defaults_Less_Than_12_Months":
      return "Telco Defaults < 12 Months ";
    case "Utility_Defaults_Less_Than_12_Months":
      return "Utility Defaults < 12 Months";
    case "Defaults-Paid":
      return "Paid Defaults";

    default:
      return '';
  }
}

export const isValidInput = (e) => {
  const splitString = e.split(".");

  if ((e === "" || regex.allowOnlyNumber.test(e)) && e?.length <= 10 && splitString[1]?.length) {
    return splitString[1]?.length <= 2;
  } else if (e[7] !== ".") {
    return e?.length <= 7;
  } else {
    return e?.length <= 10;
  }
};

export const formatAmount = (amount) => {
  if (Number(amount)?.toLocaleString("en-US", dollarStringOptions) === "$NaN") {
    return `$0.00`;
  }

  return Number(amount)?.toLocaleString("en-US", dollarStringOptions);
};

export const getStringTitleCase = (value) => {
  let val = value ? startCase(value) : ""

  return val;
};

export const updateLenderName = (lenderName) => {
  switch (lenderName) {
    case "Angle Finance":
      return "Angle";

    // case "Dynamoney":
    //   return "Grow";

    default:
      return lenderName;
  }
};

export const isValidNumberWithDecimalInput = (e) => {

  const isValid = regex.allowOnlyOptionalNumberWithDecimal.test(e)
  return isValid
};

export const rateIsValid = (rate) => {
  const splitString = rate.split(".");
  if (regex.percentageRegex.test(+rate) && rate?.length <= 6 && splitString[1]?.length) {
    return splitString[1]?.length <= 2;
  } else if (rate[3] !== ".") {
    return rate?.length <= 3 && regex.percentageRegex.test(+rate);
  } else {
    return rate?.length <= 6;
  }
};

export const servicesName = (name) => {
  switch (name) {
    case "All":
      return "All";
    case "bank_statements":
      return "Bank statements";
    case "asic_extract":
      return "ASIC extract";
    case "asic_extract_ppsr":
      return "ASIC extract PPSR";
    case "id_verification":
      return "Id verification";
    case "blug_flag":
      return "Blue flag";
    case "equifax_basic_report":
      return "Equifax basic report";
    case "equifax_commercial_report":
      return "Equifax commercial report";
    case "equifax_with_onescore_report":
      return "Equifax with Onescore report";
    default:
      return name;
  }
};

