import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { reusableFetch, reusableWithoutTokenFetch } from "src/utils/useAxios";

export const getUser = createAsyncThunk(
  "user",
  async ({}, { rejectWithValue }) => {
    const response = await reusableFetch(`user`, "GET", rejectWithValue);
    return response;
  }
);

export const registerUser = createAsyncThunk(
  "register/user",
  async (data, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/register/`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const updateUserMember = createAsyncThunk(
  "user/member",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/member`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const loginUser = createAsyncThunk(
  "login/user",
  async (data, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/login/`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  "forgotpassword/user",
  async ({ email }, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/forget_password/`,
      "POST",
      JSON.stringify({
        email,
      }),
      rejectWithValue
    );
    return response;
  }
);

export const updatePassword = createAsyncThunk(
  "updatepassword/user",
  async ({ email, password }, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/forget_password/`,
      "POST",
      {
        email,
        password,
      },
      rejectWithValue
    );
    return response;
  }
);

export const validateToken = createAsyncThunk(
  "validate",
  async (token, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/validate`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const isEmailExist = createAsyncThunk(
  "email/isExist",
  async (email, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/email/isExist/${email}`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const validateEmail = createAsyncThunk(
  "validate/email",
  async (email, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/email/validate/${email}`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const verifyEmail = createAsyncThunk(
  "verify/email",
  async (key, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/email/verify/${key}`,
      "GET",
      rejectWithValue
    );
    return response?.data;
  }
);

export const approveUser = createAsyncThunk(
  "approve/user",
  async (userId, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user/approve/${userId}`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const requestResetPassword = createAsyncThunk(
  "reset-password/request",
  async (email, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user-credentials/reset-password/request/${email}`,
      "GET",
      null,
      rejectWithValue
    );
    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "reset-password/reset",
  async (data, { rejectWithValue }) => {
    const response = await reusableWithoutTokenFetch(
      `user-credentials/reset-password/reset`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "user/update-phoneNumber",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/phone-number`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const changePassword = createAsyncThunk(
  "user-credentials/change-password",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user-credentials/change`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const updateSettings = createAsyncThunk(
  "settings/update",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `settings`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const sendEnquiry = createAsyncThunk(
  "settings/update",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/enquiry`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const getAllTransactions = createAsyncThunk(
  "transactions/get",
  async ({}, { rejectWithValue }) => {
    const response = await reusableFetch(
      `transactions/all`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const createSubscription = createAsyncThunk(
  "subscription/create",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `billing/subscription`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const getCustomerBilling = createAsyncThunk(
  "customer-billing/get",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `billing/customer`,
      "GET",
      rejectWithValue
    );
    return response;
  }
);

export const getAllUsers = createAsyncThunk(
  "all/get",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `user/all`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const createLenderSetting = createAsyncThunk(
  "user/createLenderSetting",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `lender-settings`,
      "POST",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const updateLenderSetting = createAsyncThunk(
  "user/updateLenderSetting",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `lender-settings`,
      "PUT",
      data,
      rejectWithValue
    );
    return response;
  }
);

export const deleteLenderSetting = createAsyncThunk(
  "user/deleteLenderSetting",
  async (data, { rejectWithValue }) => {
    const response = await reusableFetch(
      `lender-settings`,
      "DELETE",
      data,
      rejectWithValue
    );
    return response;
  }
);

const initialState = {
  emailInUse: false,
  isCreatingAccount: false,
  registerFormStepFirst: false,
  registerFormStepSecond: false,
  registerFormStepThird: false,
  createAccountSuccess: false,
  changePasswordFail: null,
  isChangingPassword: null,
  changePasswordSuccess: false,
  templateSaveSuccess: false,
  transactions: null,
  clientSecret: null,
  isCreatingSubscription: false,
  all: {},
  users: {
    docs: [],
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveValue: (state, action) => {
      return _.merge(state, action.payload);
    },
    resetState: () => initialState,
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data.data);
    },
    [getUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [getUser.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [getAllUsers.fulfilled]: (state, { payload }) => {
      state.users = payload?.data?.data;
    },
    [getAllUsers.pending]: (state) => {
      state.isUpdating = true;
    },
    [getAllUsers.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [updateUser.fulfilled]: (state, { payload }) => {
      if (payload.data.data) state = _.merge(state, payload.data.data.user);
      state.isUpdating = false;
      state.updateSuccess = true;
    },
    [updateUser.pending]: (state) => {
      state.isUpdating = true;
    },
    [updateUser.rejected]: (state) => {
      state.isCreatingAccount = false;
    },

    [registerUser.fulfilled]: (state, { payload }) => {
      state.isCreatingAccount = false;
      state.registerFormStepFirst = false;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data);
      state.createAccountSuccess = true;
    },
    [registerUser.pending]: (state) => {
      state.registerFormStepFirst = true;
      state.isCreatingAccount = true;
    },
    [registerUser.rejected]: (state) => {
      state.registerFormStepFirst = false;
      state.isCreatingAccount = false;
    },

    [loginUser.fulfilled]: (state, { payload }) => {
      state.errorMessage = null;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data.user);
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },

    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.successMessage = payload.message;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [forgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [forgotPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [updatePassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.email = payload?.email;
      state.successMessage = payload.message;
    },
    [updatePassword.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [updatePhoneNumber.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.successMessage = payload.message;
      state = _.merge(state, payload.data.data);
    },
    [updatePhoneNumber.pending]: (state) => {
      state.isFetching = true;
    },
    [updatePhoneNumber.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [validateToken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state = _.merge(state, payload.data.data);
    },
    [validateToken.pending]: (state) => {
      state.isFetching = true;
    },
    [validateToken.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [validateEmail.fulfilled]: (state, { payload }) => {
      console.log("payload.data", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.emailInUse = payload.data.data.emailInUse;
    },
    [validateEmail.pending]: (state) => {
      state.isFetching = true;
    },
    [validateEmail.rejected]: (state, payload) => {
      // console.log("payload.data", payload)
      state.isFetching = false;
      state.isError = true;
      // state.emailInUse = "Email is already exist";
    },
    [isEmailExist.fulfilled]: (state, { payload }) => {
      console.log("payload.data", payload.data);
      state.isFetching = false;
      state.isSuccess = true;
      state.emailInUse = payload.data.data.emailInUse;
    },
    [isEmailExist.pending]: (state) => {
      state.isFetching = true;
    },
    [isEmailExist.rejected]: (state, payload) => {
      // console.log("payload.data", payload)
      state.isFetching = false;
      state.isError = true;
      // state.emailInUse = "Email is already exist";
    },
    // [verifyEmail.fulfilled]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isSuccess = true;
    //   // state.emailInUse = payload.data.data.emailInUse;
    // },
    // [verifyEmail.pending]: (state) => {
    //   state.isFetching = true;
    // },
    // [verifyEmail.rejected]: (state, payload) => {
    //   // console.log("payload.data", payload)
    //   state.isFetching = false;
    //   state.isError = true;
    //   // state.emailInUse = "Email is already exist";
    // },

    [requestResetPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isResettingPassword = false;
      state.isSuccess = true;
    },
    [requestResetPassword.pending]: (state) => {
      state.isFetching = true;
      state.isResettingPassword = true;
    },
    [requestResetPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isResettingPassword = false;
    },

    [resetPassword.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      localStorage.token = payload.data.data.token;
      state = _.merge(state, payload.data.data.user);
    },
    [resetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [resetPassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [changePassword.fulfilled]: (state, { payload }) => {
      localStorage.token = payload.data.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data.data.user);
    },
    [changePassword.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [changePassword.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
    },

    [updateSettings.fulfilled]: (state, { payload }) => {
      localStorage.token = payload.data.data.token;
      state.isChangingPassword = false;
      state.changePasswordSuccess = true;
      state = _.merge(state, payload.data);
      state.templateSaveSuccess = true;
    },
    [updateSettings.pending]: (state) => {
      state.isChangingPassword = true;
      state.changePasswordFail = true;
    },
    [updateSettings.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
      state.isChangingPassword = false;
      state.changePasswordFail = true;
    },

    [getAllTransactions.fulfilled]: (state, { payload }) => {
      state = _.merge(state.transactions, payload.data.data);
    },
    [getAllTransactions.pending]: (state) => {
      state.isChangingPassword = true;
    },
    [getAllTransactions.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },

    [createSubscription.fulfilled]: (state, { payload }) => {
      state = _.merge(state, payload.data.data);
      state.isCreatingSubscription = false;
    },
    [createSubscription.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [createSubscription.rejected]: (state) => {
      state.isCreatingSubscription = false;
    },

    [getCustomerBilling.fulfilled]: (state, { payload }) => {
      state.isCreatingSubscription = false;
      state = _.merge(state, payload.data.data);
    },
    [getCustomerBilling.pending]: (state) => {
      state.isChangingPassword = true;
      state.isCreatingSubscription = true;
    },
    [getCustomerBilling.rejected]: (state) => {
      state.isCreatingSubscription = false;
    },

    [createLenderSetting.pending]: (state) => {
      state.lenderSettings = [];
    },
    [createLenderSetting.rejected]: (state) => {
      state.lenderSettings = [];
    },
    [createLenderSetting.fulfilled]: (state, { payload }) => {
      state.lenderSettings = payload?.data?.data;
    },

    // [updateLenderSetting.pending]: (state) => {},
    // [updateLenderSetting.rejected]: (state) => {},
    // [updateLenderSetting.fulfilled]: (state, { payload }) => {
    // state.lenderSettings = payload.data;
    // },

    [deleteLenderSetting.pending]: (state) => {},
    [deleteLenderSetting.rejected]: (state) => {},
    [deleteLenderSetting.fulfilled]: (state, { payload }) => {
      state.lenderSettings = payload.data.data;
    },
  },
});

export const { saveValue, resetState } = userSlice.actions;

export const userSelector = (state) => state.user;
