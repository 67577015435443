import * as React from "react";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { MentionsInput, Mention } from "react-mentions";
import {
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
  LinearProgress,
  Avatar,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import io from "socket.io-client";
import TimeAgoComponent from "../Utils/TimeAgoComponent";
import { addApplicationComment } from "../../store/slices/applicationFormSlice";
import { getUsersForTagging } from "../../store/slices/usersSlice";
import parseComment from "../../utils/parseComment";

const Comments = React.memo(({ comments = [], applicationId, gettingApplicationComment }) => {
  const dispatch = useDispatch();
  const [socket, setSocket] = React.useState(null);
  const [commentsState, setCommentsState] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(false);
  const [currentRoom, setCurrentRoom] = React.useState(null);
  const [newComment, setNewComment] = React.useState("");
  const [newPlainTextComment, setNewPlainTextComment] = React.useState("");
  const [mentions, setMentions] = React.useState([]);

  const mentionInputStyle = {
    input: {
      overflowY: "auto",
      height: "auto",
      padding: 9,
      borderColor: "black",
      border: "none",
    },
    highlighter: {
      boxSizing: "border-box",
      overflow: "hidden",
      height: "auto",
      lineHeight: "21px",
      minHeight: "72px",
      maxHeight: "220px",
      backgroundColor: "white",
    },
    control: {
      background: "transparent",
      marginBottom: "16px",
      transition: "all 0.3s",
      borderRadius: "2px",
      border: "1px solid black",
    },

    "&multiLine": {
      control: {},
      highlighter: {
        padding: 8,
      },
      input: {
        padding: 10,
      },
    },

    "&singleLine": {
      display: "inline-block",
      width: 180,

      highlighter: {
        padding: 9,
        border: "2px inset transparent",
      },
      input: {
        padding: 9,
        border: "2px inset",
      },
    },

    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: deepPurple[100],
        },
      },
    },
  };

  const mentionStyle = {
    color: deepPurple[500],
    position: "relative",
    textShadow: "1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white",
    zIndex: 1,
  };

  React.useEffect(() => {
    if (comments.length > 0) {
      setCommentsState(comments.slice(0, 10));
      setHasMore(comments.length > 10);
    }
  }, [comments]);

  React.useEffect(() => {
    if (!socket) {
      setSocket(
        io(`${process.env.REACT_APP_API_URL}/applicationComment`, {
          transports: ["websocket", "polling"],
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          timeout: 10000,
          auth: {
            "x-access-token": localStorage.getItem("token") || "",
          },
        }),
      );
    }

    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  React.useEffect(() => {
    if (!socket) return;
    if (applicationId) {
      if (currentRoom && currentRoom !== applicationId) {
        socket.emit("leaveApplicationCommentRoom", currentRoom, () => {
          socket.emit("joinApplicationCommentRoom", applicationId);
          socket.disconnect();
          setCurrentRoom(applicationId);
        });
      } else if (!currentRoom) {
        socket.emit("joinApplicationCommentRoom", applicationId);
        setCurrentRoom(applicationId);
      }

      const handleNewComment = (newComment) => {
        setCommentsState((prevComments) => [newComment, ...prevComments]);
      };

      const handleConnect = () => {
        socket.emit("joinApplicationCommentRoom", applicationId);
      };

      const handleReconnect = () => {
        socket.emit("joinApplicationCommentRoom", applicationId);
      };

      socket.on("newComment", handleNewComment);
      socket.on("connect", handleConnect);
      socket.on("reconnect", handleReconnect);

      return () => {
        socket.emit("leaveApplicationCommentRoom", currentRoom);
        socket.off("newComment", handleNewComment);
        socket.off("connect", handleConnect);
        socket.off("reconnect", handleReconnect);
      };
    }
  }, [applicationId, currentRoom, socket]);

  const handle = {
    handleAddComment: () => {
      if (newComment !== "" && newPlainTextComment !== "") {
        dispatch(
          addApplicationComment({ comment: newComment, plainText: newPlainTextComment, mentions, applicationId }),
        );
        setNewComment("");
        setNewPlainTextComment("");
        setMentions([]);
      }
    },
    fetchMoreData: () => {
      if (commentsState.length >= comments.length) {
        setHasMore(false);
        return;
      }
      const nextBatch = comments.slice(commentsState.length, commentsState.length + 10);
      setCommentsState((prevComments) => [...prevComments, ...nextBatch]);
    },
    handleFetchSuggestions: async (query, callback) => {
      const result = await dispatch(getUsersForTagging(query)).unwrap();
      const taggableUsers = result.data.data ?? [];
      callback(
        taggableUsers.map((user) => ({
          id: user._id,
          display: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
          userEmail: `${user?.userEmail || ""}`.trim(),
        })),
      );
    },
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "0 15px 0 15px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography
          style={{
            marginBottom: 20,
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Comments
        </Typography>
      </Stack>
      {gettingApplicationComment ? (
        <Box sx={{ margin: "auto" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "calc(77vh - 77px)",
            height: "calc(77vh - 77px)",
            flexGrow: 1,
            marginBottom: 0.5,
            overflow: "auto",
            padding: "0px 0px 16px 0px",
            scrollbarWidth: "thin",
          }}
          id="scrollableBox"
        >
          <InfiniteScroll
            dataLength={commentsState.length}
            next={handle.fetchMoreData}
            hasMore={hasMore}
            loader={<LinearProgress />}
            scrollableTarget="scrollableBox"
          >
            <List sx={{ width: "100%", maxWidth: "97%" }}>
              {commentsState.map((comment, index) => (
                <React.Fragment key={comment._id}>
                  <ListItem alignItems="flex-start" sx={{ padding: "8px 0px 8px 0px" }}>
                    <ListItemAvatar>
                      <Avatar>
                        {comment.user.firstName[0]}
                        {comment.user.lastName[0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <Typography variant="">{`${comment.user.firstName} ${comment.user.lastName}`}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            <TimeAgoComponent timestamp={comment.createdAt} />
                          </Typography>
                        </div>
                      }
                      secondary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          style={{ wordWrap: "break-word", whiteSpace: "pre-line" }}
                        >
                          {parseComment(comment.comment).map((segment, index) => {
                            if (segment.type === "mention") {
                              return (
                                <Mention
                                  key={index}
                                  style={{
                                    backgroundColor: deepPurple[50],
                                    color: deepPurple[700],
                                    padding: "1px 2px",
                                    borderRadius: "3px",
                                  }}
                                  display={`@${segment.display}`}
                                  id={segment.id}
                                />
                              );
                            } else {
                              return <span key={index}>{segment.text}</span>;
                            }
                          })}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < commentsState.length - 1 && <Divider variant="inset" component="li" />}
                </React.Fragment>
              ))}
              {!hasMore && commentsState.length > 0 && (
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center", width: "100%", marginTop: 5 }}
                      >
                        No more comments
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </InfiniteScroll>
        </Box>
      )}
      <Box sx={{ position: "sticky", bottom: 0, padding: "16px 0" }}>
        <MentionsInput
          value={newComment}
          onChange={(e, newValue, newPlainTextValue, newMentions) => {
            setNewComment(newValue);
            setNewPlainTextComment(newPlainTextValue);
            setMentions(newMentions.map((m) => ({ _id: m.id, display: m.display })));
          }}
          style={mentionInputStyle}
          allowSuggestionsAboveCursor
          spellcheck="false"
        >
          <Mention
            trigger="@"
            data={handle.handleFetchSuggestions}
            displayTransform={(id, display) => `@${display}`}
            markup="@[__display__](__id__)"
            style={mentionStyle}
            renderSuggestion={(suggestion, search, highlightedDisplay) => (
              <>
                <span>{highlightedDisplay}</span>
                <span style={{ marginLeft: "5px", color: "gray" }}>
                  {suggestion?.userEmail || ""}
                </span>
              </>
            )}
          />
        </MentionsInput>
        <Button variant="contained" color="primary" fullWidth onClick={handle.handleAddComment}>
          Add Comment
        </Button>
      </Box>
    </Box>
  );
});

export default Comments;
